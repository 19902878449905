// see https://remix.run/docs/en/main/styling/css-in-js
// much of this code has been copied from https://github.com/remix-run/examples/tree/10d330f58c512470ff59cf3b9a49d7ccb7bdeda2/emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/react';
import React, { createContext, useCallback, useRef, useState } from 'react';

export interface ClientStyleContextValue {
  reset: () => void;
}

const ClientStyleContext = createContext<ClientStyleContextValue>({
  reset: () => {},
});

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

export const createClientCache = () => createCache({ key: 'remix-css' });

export const ClientCacheProvider = ({ children }: ClientCacheProviderProps) => {
  const [cache, setCache] = useState(() => createClientCache());

  const reset = useCallback(() => {
    setCache(createClientCache());
  }, []);

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
};

export const useReinjectEmotionCache = (emotionCache: EmotionCache) => {
  const { reset } = React.useContext(ClientStyleContext);
  const reinjectStylesRef = useRef(true);

  React.useEffect(() => {
    if (!reinjectStylesRef.current) {
      return;
    }

    // re-link sheet container
    emotionCache.sheet.container = document.head;

    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });

    // reset cache to re-apply global styles
    reset();
    // ensure we only do this once per mount
    reinjectStylesRef.current = false;
  }, [emotionCache, reset]);
};
